import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import Slider from '@jsv2/components/Slider/Slider';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable, EditableImg } from '@jsv3/context/EditFieldContext';
import LPContext from '@jsv3/context/LPContext';
import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';
import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';
import { preparedAvailableItemsForRender } from '@jsv3/pages/LandingPage/utils';

// Main slider settings
const mainSliderSettings = {
  slidesPerView: 1,
  loop: false,
  pagination: true,
  breakpoints: {
    767: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: 3,
    },
  },
  className: 'testimonials-list__item-wrapper',
};

// Gallery settings
const gallerySettings = {
  slidesPerView: 1,
  navigation: true,
  loop: true,
  className: 'testimonials-list__gallery',
};

/**
 * Testimonials
 *
 * @param {string} id
 * @param {showOneAction} boolean
 *
 * @return {*}
 *
 * @constructor
 */
const Testimonial = ({ id, showOneAction }) => {
  const screenTypeContext = useContext(ScreenTypeContext);
  const { editableFields, editableImageFields } = useContext(EditFieldContext);
  const { renderBecomeButton, isEditSectionMode } = useContext(LPContext);

  const [mainSlider, setMainSlider] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isLastSlide, setLastSlide] = useState(false);
  const [isFirstSlide, setFirstSlide] = useState(true);

  const testimonials = [
    {
      id: 1,
      images: [
        {
          id: 1,
          url: editableImageFields.testimonial_1_image_1,
        },
        {
          id: 2,
          url: editableImageFields.testimonial_1_image_2,
        },
        {
          id: 3,
          url: editableImageFields.testimonial_1_image_3,
        },
      ],
      title: editableFields.testimonial_1_title,
      content: editableFields.testimonial_1_content,
      link: editableFields.testimonial_1_link,
    },
    {
      id: 2,
      images: [
        {
          id: 1,
          url: editableImageFields.testimonial_2_image_1,
        },
        {
          id: 2,
          url: editableImageFields.testimonial_2_image_2,
        },
        {
          id: 3,
          url: editableImageFields.testimonial_2_image_3,
        },
      ],
      title: editableFields.testimonial_2_title,
      content: editableFields.testimonial_2_content,
      link: editableFields.testimonial_2_link,
    },
    {
      id: 3,
      images: [
        {
          id: 1,
          url: editableImageFields.testimonial_3_image_1,
        },
        {
          id: 2,
          url: editableImageFields.testimonial_3_image_2,
        },
        {
          id: 3,
          url: editableImageFields.testimonial_3_image_3,
        },
      ],
      title: editableFields.testimonial_3_title,
      content: editableFields.testimonial_3_content,
      link: editableFields.testimonial_3_link,
    },
  ];

  const preparedTestimonialsForRender = preparedAvailableItemsForRender(
    testimonials,
    isEditSectionMode,
    ['link'],
  );

  /**
   * @param newIndex
   */
  const beforeMainSliderChange = (newIndex) => {
    setCurrentSlide(newIndex);
    setLastSlide(newIndex === preparedTestimonialsForRender.length - 1);
    setFirstSlide(newIndex === 0);
  };

  return (
    editableFields && (
      <section
        className={`testimonials-section${
          showOneAction ? ' testimonials-section--one-action' : ''
        }`}
        id={id}
      >
        <div className="main-container">
          <h2 className="section-title section-title--center section-title--with-underline">
            <Editable fieldValue={editableFields.testimonials_heading} />
          </h2>

          <div className="testimonials-list">
            <Slider
              sliderProps={{
                onSwiper: setMainSlider,
                onSlideChange: ({ activeIndex }) => beforeMainSliderChange(activeIndex),
                ...mainSliderSettings,
              }}
            >
              {preparedTestimonialsForRender.map((testimonial) => (
                <div key={testimonial.id} className="testimonials-list__item">
                  <Slider sliderProps={{ ...gallerySettings }}>
                    {testimonial.images.map((item) => (
                      <div key={item.id} className="testimonials-list__gallery-item">
                        {item.url && (
                          <EditableImg image={item.url} width={360} height={360}>
                            <Image
                              data={item.url && item.url.content}
                              type="PageContent"
                              config={{
                                size: IMAGE_SIZES.SMALL,
                                squared: true,
                              }}
                              background
                            />
                          </EditableImg>
                        )}
                      </div>
                    ))}
                  </Slider>

                  <div className="testimonials-list__item-title common-title">
                    <Editable fieldValue={testimonial.title} />
                  </div>

                  <div className="testimonials-list__item-content common-text">
                    <Editable fieldValue={testimonial.content} />
                  </div>

                  {!showOneAction && (
                    <>
                      <div className="testimonials-list__item-link">
                        <Editable fieldValue={testimonial.link} isOptionalField />
                      </div>

                      {renderBecomeButton(
                        'become-btn become-btn--with-border',
                        window.templateVariables.body_cta_text.content,
                        `become-join-btn-testimonial-${testimonial.id}`,
                      )}
                    </>
                  )}
                </div>
              ))}
            </Slider>

            {isMobile(screenTypeContext) && (
              <div className="testimonials-list__slider-nav">
                <button
                  type="button"
                  className={`testimonials-list__slider-arrow testimonials-list__slider-arrow--prev ${
                    isFirstSlide ? 'disabled' : ''
                  }`}
                  onClick={() => mainSlider.slidePrev()}
                />

                <span>
                  {currentSlide + 1} / {preparedTestimonialsForRender.length}
                </span>

                <button
                  type="button"
                  className={`testimonials-list__slider-arrow testimonials-list__slider-arrow--next ${
                    isLastSlide ? 'disabled' : ''
                  }`}
                  onClick={() => mainSlider.slideNext()}
                />
              </div>
            )}

            {showOneAction &&
              renderBecomeButton(
                'become-btn become-btn--red',
                window.templateVariables.body_cta_text.content,
                `become-join-btn-testimonial`,
              )}
          </div>
        </div>
      </section>
    )
  );
};

Testimonial.propTypes = {
  id: PropTypes.string.isRequired,
  showOneAction: PropTypes.bool,
};

Testimonial.defaultProps = {
  showOneAction: false,
};

export default ErrorBoundaryDecorator()(Testimonial);
