import React, { useState, useEffect, useContext, useMemo } from 'react';
import classNames from 'classnames';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import LPContext from '@jsv3/context/LPContext';

/**
 * Section tools
 *
 * @param isVisible {boolean}
 * @param initialOrder {number}
 * @param children {JSXElement}
 * @return {JSXElement}
 */
const SectionTools = ({ isVisible, order: initialOrder, children }) => {
  const [currentOrder, setCurrentOrder] = useState(initialOrder);

  const { config, updateConfig } = useContext(LPContext);

  const isArrowUpDisabled = useMemo(() => currentOrder === 0, [currentOrder]);
  const isArrowDownDisabled = useMemo(() => currentOrder === config.length - 1, [
    currentOrder,
    config.length,
  ]);
  const isApplyOrderDisabled = useMemo(() => initialOrder === currentOrder, [
    initialOrder,
    currentOrder,
  ]);

  const arrowUpHandler = () => {
    setCurrentOrder((prevState) => prevState - 1);
  };

  const arrowDownHandler = () => {
    setCurrentOrder((prevState) => prevState + 1);
  };

  const onApplyOrderHandler = () => {
    const updatedConfig = config.map((item) => {
      if (item.order === initialOrder) {
        return {
          ...item,
          order: currentOrder,
        };
      }

      if (item.order === currentOrder) {
        return {
          ...item,
          order: initialOrder,
        };
      }

      return item;
    });

    updateConfig(updatedConfig);
  };

  const onToggleVisibleHandler = () => {
    const updatedConfig = config.map((item) => {
      if (item.order === initialOrder) {
        return {
          ...item,
          isVisible: !item.isVisible,
        };
      }

      return item;
    });

    updateConfig(updatedConfig);
  };

  // const replaceSectionHandler = () => {};

  useEffect(() => {
    setCurrentOrder(initialOrder);
  }, [initialOrder]);

  return (
    <div className={classNames('editable-section-wrapper', { 'section-is-hidden': !isVisible })}>
      {children}

      <div className="section-tools-wrapper">
        <div className="section-tools">
          <div className="section-toggle-visible-tool">
            <button
              type="button"
              onClick={onToggleVisibleHandler}
              title={`Section is ${isVisible ? 'visible' : 'hidden'}`}
            >
              <img src={`/images/icons/black-eye-${isVisible ? 'on' : 'off'}.svg`} alt="eye" />
            </button>
          </div>

          <div className="section-arrows-tool">
            <div>
              <button type="button" onClick={arrowUpHandler} disabled={isArrowUpDisabled}>
                <img
                  src="/images/icons/arrow-down-black.svg"
                  alt="arrow up"
                  style={{ transform: 'rotate(90deg)' }}
                />
              </button>

              <span>{currentOrder + 1}</span>

              <button
                type="button"
                onClick={arrowDownHandler}
                disabled={isArrowDownDisabled}
                style={{ transform: 'rotate(-90deg)' }}
              >
                <img src="/images/icons/arrow-down-black.svg" alt="arrow down" />
              </button>
            </div>

            <button type="button" onClick={onApplyOrderHandler} disabled={isApplyOrderDisabled}>
              Apply order
            </button>
          </div>

          {/* <div className="section-replace-tool"> */}
          {/* replace */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default ErrorBoundaryDecorator()(SectionTools);
