import React, { useContext } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv3/context/EditFieldContext';
import LPContext from '@jsv3/context/LPContext';
import { preparedAvailableItemsForRender } from '@jsv3/pages/LandingPage/utils';
import { HOME_PAGE_TOKEN_URL } from '@jsv2/Enums/UrlEnums';
import SliderWithBorder from './SliderWithBorder';

const FreeUpSection = ({ id }) => {
  const { editableFields, editableImageFields } = useContext(EditFieldContext);
  const { isEditSectionMode } = useContext(LPContext);

  const sliderItems = [
    {
      id: 1,
      image: editableImageFields.free_up_image,
    },
    {
      id: 2,
      image: editableImageFields.free_up_image_2,
    },
    {
      id: 3,
      image: editableImageFields.free_up_image_3,
    },
  ];

  return (
    <>
      {editableFields && (
        <section className="free-up-section" id={id}>
          <div className="main-container">
            <div className="free-up-section__wrapper">
              <SliderWithBorder
                items={preparedAvailableItemsForRender(sliderItems, isEditSectionMode)}
                link={HOME_PAGE_TOKEN_URL}
              />

              <div className="free-up-section__info">
                <div className="section-title section-title--with-underline">
                  <Editable fieldValue={editableFields.free_up_heading} />
                </div>

                <div className="section-description">
                  <Editable fieldValue={editableFields.free_up_testimonial} />
                </div>

                <div className="travel-section__list travel-section__list--lp4">
                  <div className="travel-section__item">
                    <div className="common-title">
                      <Editable fieldValue={editableFields.free_up_col_1_title} />
                    </div>

                    <div className="common-text">
                      <Editable fieldValue={editableFields.free_up_col_1_content} />
                    </div>
                  </div>

                  <div className="travel-section__item">
                    <div className="common-title">
                      <Editable fieldValue={editableFields.free_up_col_2_title} />
                    </div>

                    <div className="common-text">
                      <Editable fieldValue={editableFields.free_up_col_2_content} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ErrorBoundaryDecorator()(FreeUpSection);
