import React, { useContext, useCallback } from 'react';

import Slider from '@jsv2/components/Slider/Slider';
import SignUpContext from '@jsv2/context/SignUpContext';
import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';
import StarRating from '@jsv2/components/StarRating';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable, EditableImg } from '@jsv3/context/EditFieldContext';
import LPContext from '@jsv3/context/LPContext';
import { preparedAvailableItemsForRender } from '@jsv3/pages/LandingPage/utils';

const { isPreviewPage } = window;

const settings = {
  pagination: {
    clickable: true,
  },
  navigation: true,
  className: 'offers__slider',
};

const OffersSliderWithReviews = React.forwardRef(({ renderBecomeButton, ...rest }, ref) => {
  const { editableFields, editableImageFields } = useContext(EditFieldContext);
  const { openModal } = useContext(SignUpContext);
  const { isEditSectionMode } = useContext(LPContext);

  const offers = [
    {
      id: 1,
      image: editableImageFields.exclusive_offers_1,
      destination: editableFields.offer_option_1_destination,
      title: editableFields.offer_option_1_title,
      price: editableFields.offer_option_1_price,
      travel_period: editableFields.offer_option_1_travel_period,
      review_author: editableFields.offer_review_1_author,
      review_content: editableFields.offer_review_1_content,
      avatar: editableImageFields.exclusive_offers_avatar_1,
      show_tripadviser: editableFields.offer_review_1_tripadviser,
    },
    {
      id: 2,
      image: editableImageFields.exclusive_offers_2,
      destination: editableFields.offer_option_2_destination,
      title: editableFields.offer_option_2_title,
      price: editableFields.offer_option_2_price,
      travel_period: editableFields.offer_option_2_travel_period,
      review_author: editableFields.offer_review_2_author,
      review_content: editableFields.offer_review_2_content,
      avatar: editableImageFields.exclusive_offers_avatar_2,
      show_tripadviser: editableFields.offer_review_2_tripadviser,
    },
    {
      id: 3,
      image: editableImageFields.exclusive_offers_3,
      destination: editableFields.offer_option_3_destination,
      title: editableFields.offer_option_3_title,
      price: editableFields.offer_option_3_price,
      travel_period: editableFields.offer_option_3_travel_period,
      review_author: editableFields.offer_review_3_author,
      review_content: editableFields.offer_review_3_content,
      avatar: editableImageFields.exclusive_offers_avatar_3,
      show_tripadviser: editableFields.offer_review_3_tripadviser,
    },
    {
      id: 4,
      image: editableImageFields.exclusive_offers_4,
      destination: editableFields.offer_option_4_destination,
      title: editableFields.offer_option_4_title,
      price: editableFields.offer_option_4_price,
      travel_period: editableFields.offer_option_4_travel_period,
      review_author: editableFields.offer_review_4_author,
      review_content: editableFields.offer_review_4_content,
      avatar: editableImageFields.exclusive_offers_avatar_4,
      show_tripadviser: editableFields.offer_review_4_tripadviser,
    },
    {
      id: 5,
      image: editableImageFields.exclusive_offers_5,
      destination: editableFields.offer_option_5_destination,
      title: editableFields.offer_option_5_title,
      price: editableFields.offer_option_5_price,
      travel_period: editableFields.offer_option_5_travel_period,
      review_author: editableFields.offer_review_5_author,
      review_content: editableFields.offer_review_5_content,
      avatar: editableImageFields.exclusive_offers_avatar_5,
      show_tripadviser: editableFields.offer_review_5_tripadviser,
    },
    {
      id: 6,
      image: editableImageFields.exclusive_offers_6,
      destination: editableFields.offer_option_6_destination,
      title: editableFields.offer_option_6_title,
      price: editableFields.offer_option_6_price,
      travel_period: editableFields.offer_option_6_travel_period,
      review_author: editableFields.offer_review_6_author,
      review_content: editableFields.offer_review_6_content,
      avatar: editableImageFields.exclusive_offers_avatar_6,
      show_tripadviser: editableFields.offer_review_6_tripadviser,
    },
  ];

  const initSignUpProcess = useCallback(() => {
    if (!isPreviewPage) {
      openModal(true);
    }
  }, [openModal]);

  const renderTripAdviserIcon = (content) => {
    if (Number(content)) {
      return <div className="bottom-info__tripadviser" />;
    }

    return null;
  };

  return (
    editableFields && (
      <div className="offers" {...rest} ref={ref}>
        <Slider sliderProps={{ ...settings }}>
          {preparedAvailableItemsForRender(offers, isEditSectionMode).map((item) => (
            <div key={item.id} className="offers__item">
              <div className="offers__item-wrapper">
                <div className={`offers__item-inner${isPreviewPage ? ' preview-mode' : ''}`}>
                  <div className="offers__image-wrapper">
                    {item.image && (
                      <EditableImg image={item.image} width={472} height={372}>
                        <Image
                          data={item.image && item.image.content}
                          type="PageContent"
                          config={{
                            size: IMAGE_SIZES.SMALL,
                          }}
                          background
                        />
                      </EditableImg>
                    )}
                  </div>

                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                  <div
                    className="offers__item-labels clickable-block"
                    role="button"
                    onClick={() => initSignUpProcess()}
                  >
                    <div className="offers__item-label offers__item-label--black">
                      <Editable fieldValue={item.price} />
                    </div>
                    <div className="offers__item-label offers__item-label--red">
                      <Editable fieldValue={item.travel_period} />
                    </div>
                  </div>

                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                  <div
                    className="offers__item-location clickable-block"
                    role="button"
                    onClick={() => initSignUpProcess()}
                  >
                    <Editable fieldValue={item.destination} />
                  </div>

                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                  <h4
                    className="offers__item-title clickable-block"
                    onClick={() => initSignUpProcess()}
                  >
                    <Editable fieldValue={item.title} />
                  </h4>
                </div>

                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                <div
                  className="offers__bottom-info bottom-info clickable-block"
                  role="button"
                  onClick={() => initSignUpProcess()}
                >
                  <div className="bottom-info__avatar">
                    {item.avatar && (
                      <EditableImg image={item.avatar} width={80} height={80}>
                        <Image
                          data={item.avatar && item.avatar.content}
                          type="PageContent"
                          config={{
                            size: IMAGE_SIZES.IMAGE_W100,
                            squared: true,
                          }}
                          background
                        />
                      </EditableImg>
                    )}
                  </div>

                  <div className="bottom-info__info">
                    <div className="bottom-info__author">
                      <div className="bottom-info__name">
                        <div className="common-text">
                          <Editable fieldValue={item.review_author} />
                        </div>

                        {renderTripAdviserIcon(item.show_tripadviser?.content)}
                      </div>

                      <StarRating stars={5} />
                    </div>

                    <div className="bottom-info__text common-text">
                      <Editable fieldValue={item.review_content} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  );
});

OffersSliderWithReviews.displayName = 'OffersSlider';

export default ErrorBoundaryDecorator()(OffersSliderWithReviews);
