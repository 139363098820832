import React, { createElement } from 'react';

import SectionTools from './SectionTools';
import sections from '../sectionsUtils';

const LpBuilder = ({ config, isEditSectionMode }) => {
  const components = config
    .sort((a, b) => a.order - b.order)
    .filter(({ isVisible }) => isVisible || isEditSectionMode)
    .reduce((acc, itemData) => {
      const { name, props } = itemData;

      acc.push({
        id: name,
        itemData,
        node: createElement(sections[name], { id: name, ...props }, null),
      });

      return acc;
    }, []);

  return (
    <>
      {components.map(({ id, itemData, node }) => (
        <React.Fragment key={id}>
          {isEditSectionMode ? <SectionTools {...itemData}>{node}</SectionTools> : node}
        </React.Fragment>
      ))}
    </>
  );
};

export default LpBuilder;
