import React, { useContext, useEffect, useState } from 'react';

import { getReviewListApi } from '@jsv3/utils/api/reviewListAPI';
import Slider from '@jsv2/components/Slider/Slider';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import Image from '@jsv2/components/Image';
import ScreenTypeContext, { selectTheAppropriateSize } from '@js/context/ScreenTypeContext';
import StarRating from '@jsv2/components/StarRating';

const sliderSettings = {
  navigation: true,
  style: {
    '--swiper-navigation-color': '#fff',
    '--swiper-pagination-color': '#fff',
    '--swiper-navigation-size': '17px',
  },
  loop: true,
  className: 'reviews-section__slider',
  autoHeight: true,
};

const ReviewsWithAvatar = () => {
  const screenTypeContext = useContext(ScreenTypeContext);

  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    getReviewListApi().then(({ data }) => {
      setReviews(data);
    });
  }, []);

  return (
    <section className="reviews-section">
      {reviews.length > 0 && (
        <Slider sliderProps={{ ...sliderSettings }}>
          {reviews.map((review) => (
            <div key={review.id} className="reviews-section__item">
              {review.background_image && (
                <Image
                  className="reviews-section__item-bg"
                  data={review.background_image}
                  config={{
                    size: selectTheAppropriateSize(screenTypeContext),
                  }}
                  background
                />
              )}

              <StarRating stars={5} />

              <div className="reviews-section__item-content">&quot;{review.content}&quot;</div>

              <div className="reviews-section__item-author">
                <span>{review.reviewer_name}</span>
                <span className="position">{review.publisher_source}</span>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </section>
  );
};

export default ErrorBoundaryDecorator()(ReviewsWithAvatar);
