import React, { useContext } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv3/context/EditFieldContext';
import MediaReviews from '@jsv3/components/molecules/MediaReviews';

/**
 * @return {JSX.Element}
 * @constructor
 */
const ReviewsWithVideo = () => {
  const { editableFields } = useContext(EditFieldContext);

  return (
    <div className="reviews-with-video">
      <div className="main-container">
        <div className="section-title section-title--with-underline section-title--center">
          <Editable fieldValue={editableFields.reviews_title} />
        </div>

        {editableFields.reviews_testimonial && (
          <div className="section-testimonial">
            <Editable fieldValue={editableFields.reviews_testimonial} />
          </div>
        )}

        <div
          className="trustpilot-widget"
          data-locale="en-US"
          data-template-id="5419b6ffb0d04a076446a9af"
          data-businessunit-id="5fea6e94b9abc40001e81561"
          data-style-height="20px"
          data-style-width="100%"
        >
          <a
            href="https://www.trustpilot.com/review/viptraveler.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Trustpilot
          </a>
        </div>

        <MediaReviews />
      </div>
    </div>
  );
};

export default ErrorBoundaryDecorator()(ReviewsWithVideo);
