import React, { useEffect, useState } from 'react';

import { getReviewListApi } from '@jsv3/utils/api/reviewListAPI';
import Slider from '@jsv2/components/Slider/Slider';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

// Slider settings
const settings = {
  navigation: true,
  style: {
    '--swiper-navigation-color': '#fff',
    '--swiper-pagination-color': '#fff',
    '--swiper-navigation-size': '17px',
  },
  loop: true,
  className: 'testimonials__slider',
  autoHeight: true,
};

/**
 * Render testimonials in a slider
 *
 * @return {*}
 *
 * @constructor
 */
const Reviews = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    getReviewListApi().then(({ data }) => {
      setReviews(data);
    });
  }, []);

  return (
    reviews.length > 0 && (
      <div className="testimonials">
        <Slider sliderProps={{ ...settings }}>
          {reviews.map((item) => (
            <div className="testimonials__item" key={item.id}>
              <div className="testimonials__quote">{item.content}</div>

              <div className="testimonials__author">
                <span className="testimonials__author_name">{item.reviewer_name}</span>{' '}
                {item.publisher_source}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  );
};

export default ErrorBoundaryDecorator()(Reviews);
