import React, { useContext } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv3/context/EditFieldContext';

const AboutSectionWithTwoRow = React.forwardRef(({ id }, ref) => {
  const { editableFields } = useContext(EditFieldContext);

  return (
    <>
      {editableFields && (
        <section className="about" id={id} ref={ref}>
          <div className="about__top-section">
            <div className="main-container about__container">
              <div className="section-title section-title--with-underline section-title--center">
                <Editable fieldValue={editableFields.about_heading} />
              </div>

              {editableFields.about_testimonial && (
                <div className="about__title">
                  <Editable fieldValue={editableFields.about_testimonial} />
                </div>
              )}

              <div className="common-text common-text--center">
                <Editable fieldValue={editableFields.about_description} />
              </div>
            </div>
          </div>

          <div className="about__bottom-section">
            <div className="main-container about__container">
              <div className="about__title">
                <Editable fieldValue={editableFields.why_now_heading} />
              </div>

              <div className="common-text common-text--center">
                <Editable fieldValue={editableFields.why_now_description} />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
});

export default ErrorBoundaryDecorator()(AboutSectionWithTwoRow);
