import React, { useContext } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable, EditableImg } from '@jsv3/context/EditFieldContext';
import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';
import LPContext from '@jsv3/context/LPContext';
import { preparedAvailableItemsForRender } from '@jsv3/pages/LandingPage/utils';
import Slider from '@jsv2/components/Slider/Slider';
import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';
import SliderWithBorder from './SliderWithBorder';

const settings = {
  pagination: {
    clickable: true,
  },
  navigation: true,
  className: 'content-grid-section__slider',
};

const ContentGridSection = React.forwardRef(({ id }, ref) => {
  const { editableFields, editableImageFields } = useContext(EditFieldContext);
  const screenTypeContext = useContext(ScreenTypeContext);
  const { isEditSectionMode } = useContext(LPContext);

  const data = [
    {
      id: 1,
      heading: editableFields.friend_with_benefits_heading_1,
      testimonial: editableFields.friend_with_benefits_testimonial_1,
      leftColumnTitle: editableFields.friend_with_benefits_col_1_title_1,
      rightColumnTitle: editableFields.friend_with_benefits_col_2_title_1,
      leftColumnContent: editableFields.friend_with_benefits_col_1_content_1,
      rightColumnContent: editableFields.friend_with_benefits_col_2_content_1,
      hasPhoneImage: false,
      sliderWithBorder: false,
      images: [
        {
          id: 1,
          image: editableImageFields.friend_with_benefits_image_1_1,
        },
        {
          id: 2,
          image: editableImageFields.friend_with_benefits_image_1_2,
        },
        {
          id: 3,
          image: editableImageFields.friend_with_benefits_image_1_3,
        },
        {
          id: 4,
          image: editableImageFields.friend_with_benefits_image_2_4,
        },
      ],
    },
    {
      id: 2,
      heading: editableFields.friend_with_benefits_heading_2,
      testimonial: editableFields.friend_with_benefits_testimonial_2,
      leftColumnTitle: editableFields.friend_with_benefits_col_1_title_2,
      rightColumnTitle: editableFields.friend_with_benefits_col_2_title_2,
      leftColumnContent: editableFields.friend_with_benefits_col_1_content_2,
      rightColumnContent: editableFields.friend_with_benefits_col_2_content_2,
      hasPhoneImage: false,
      sliderWithBorder: true,
      images: [
        {
          id: 1,
          image: editableImageFields.friend_with_benefits_image_2_1,
        },
        {
          id: 2,
          image: editableImageFields.friend_with_benefits_image_2_2,
        },
        {
          id: 3,
          image: editableImageFields.friend_with_benefits_image_2_3,
        },
      ],
    },
    {
      id: 3,
      heading: editableFields.friend_with_benefits_heading_3,
      testimonial: editableFields.friend_with_benefits_testimonial_3,
      leftColumnTitle: editableFields.friend_with_benefits_col_1_title_3,
      rightColumnTitle: editableFields.friend_with_benefits_col_2_title_3,
      leftColumnContent: editableFields.friend_with_benefits_col_1_content_3,
      rightColumnContent: editableFields.friend_with_benefits_col_2_content_3,
      hasPhoneImage: true,
      sliderWithBorder: false,
      images: [
        {
          id: 1,
          image: editableImageFields.friend_with_benefits_image_3,
        },
      ],
    },
  ];

  /**
   * @param {object} item
   * @return {JSX.Element}
   */
  const renderSlider = (item) => {
    if (item.sliderWithBorder) {
      return (
        <SliderWithBorder items={preparedAvailableItemsForRender(item.images, isEditSectionMode)} />
      );
    }

    return (
      <Slider
        sliderProps={{
          ...settings,
        }}
      >
        {preparedAvailableItemsForRender(item.images, isEditSectionMode)
          .filter((el) => el.image)
          .map((el) => (
            <div key={el.id} className="content-grid-section__slide">
              <EditableImg image={el.image} width={555} height={406}>
                <Image
                  data={el.image && el.image.content}
                  type="PageContent"
                  config={{
                    size: isMobile(screenTypeContext) ? IMAGE_SIZES.IMAGE_W350 : IMAGE_SIZES.SMALL,
                  }}
                  background
                />
              </EditableImg>
            </div>
          ))}
      </Slider>
    );
  };

  /**
   * @param {object} item
   * @return {JSX.Element}
   */
  const renderSection = (item) => (
    <section
      id={id}
      ref={ref}
      className={`travel-section travel-section--lp4 content-grid-section${
        item.hasPhoneImage ? ' stylists inverted p-0' : ''
      }`}
    >
      <div className="main-container">
        {isMobile(screenTypeContext) && !item.hasPhoneImage && (
          <div className="section-title section-title--with-underline">
            <Editable fieldValue={item.heading} />
          </div>
        )}

        <div className={`travel-section__wrapper${item.hasPhoneImage ? ' stylists__wrapper' : ''}`}>
          <div
            className={`travel-section__info travel-section__info--lp4${
              item.hasPhoneImage ? ' stylists__info' : ''
            }`}
          >
            <div className="travel-section__info-wrapper travel-section__info-wrapper--lp4">
              {(!isMobile(screenTypeContext) || item.hasPhoneImage) && (
                <div className="section-title section-title--with-underline">
                  <Editable fieldValue={item.heading} />
                </div>
              )}

              <div className="section-description">
                <Editable fieldValue={item.testimonial} />
              </div>

              <div className="travel-section__list travel-section__list--lp4">
                <div className="travel-section__item">
                  <div className="common-title">
                    <Editable fieldValue={item.leftColumnTitle} />
                  </div>

                  <div className="common-text">
                    <Editable fieldValue={item.leftColumnContent} />
                  </div>
                </div>

                <div className="travel-section__item">
                  <div className="common-title">
                    <Editable fieldValue={item.rightColumnTitle} />
                  </div>

                  <div className="common-text">
                    <Editable fieldValue={item.rightColumnContent} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!item.hasPhoneImage ? (
            renderSlider(item)
          ) : (
            <div className="stylists__image-wrapper">
              <div className="stylists__icon stylists__icon--champagne" />

              <div className="stylists__icon stylists__icon--driver" />

              <div className="stylists__icon stylists__icon--sandal" />

              <div className="stylists__icon stylists__icon--vip" />

              <div className="stylists__image" />
            </div>
          )}
        </div>
      </div>
    </section>
  );

  return (
    <>
      {editableFields && (
        <>
          {renderSection(data[0])}

          <section className="free-up-section gray-section content-grid-section" id={id}>
            <div className="main-container">
              {isMobile(screenTypeContext) && (
                <div className="section-title section-title--with-underline section-title--with-icon">
                  <Editable fieldValue={data[1].heading} />

                  <img
                    alt=""
                    src="/images/lp/promo3/icons/telescope.svg"
                    className="section-title-icon"
                  />
                </div>
              )}

              <div className="free-up-section__wrapper">
                <SliderWithBorder
                  items={preparedAvailableItemsForRender(data[1].images, isEditSectionMode)}
                />

                <div className="free-up-section__info">
                  {!isMobile(screenTypeContext) && (
                    <div className="section-title section-title--with-underline section-title--with-icon">
                      <Editable fieldValue={data[1].heading} />

                      <img
                        alt=""
                        src="/images/lp/promo3/icons/telescope.svg"
                        className="section-title-icon"
                      />
                    </div>
                  )}

                  <div className="section-description">
                    <Editable fieldValue={data[1].testimonial} />
                  </div>

                  <div className="travel-section__list travel-section__list--lp4">
                    <div className="travel-section__item">
                      <div className="common-title">
                        <Editable fieldValue={data[1].leftColumnTitle} />
                      </div>

                      <div className="common-text">
                        <Editable fieldValue={data[1].leftColumnContent} />
                      </div>
                    </div>

                    <div className="travel-section__item">
                      <div className="common-title">
                        <Editable fieldValue={data[1].rightColumnTitle} />
                      </div>

                      <div className="common-text">
                        <Editable fieldValue={data[1].rightColumnContent} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {renderSection(data[2])}
        </>
      )}
    </>
  );
});

ContentGridSection.displayName = 'ContentGridSection';

export default ErrorBoundaryDecorator()(ContentGridSection);
