import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import apiClient from '@js/apiClient';
import UserContext from '@jsv2/context/UserContext';
import ChooseDepositPlanStepDefault from '@jsv2/components/Authorization/ChooseDepositPlanStepDefault';
import ChoosePlan from '@jsv3/pages//LandingPage/UI/ChoosePlan';

const ChoosePlanStep = ({ step, getNextStep }) => {
  const { quizAnswers } = useContext(UserContext);

  useEffect(() => {
    if (quizAnswers.length > 0) {
      apiClient.put('/user/survey-question-answer', { answers: quizAnswers });
    }
  }, []);

  return (
    <ChooseDepositPlanStepDefault
      step={step}
      getNextStep={getNextStep}
      render={({ onSelectPlan }) => (
        <div className="viptraveler-modal">
          <div className="signup signup--step3 signup--plans">
            <div className="vt-common-promo-landing">
              <div className="choose-plan__wrapper">
                <div className="plans-block__heading">
                  <h2 className="heading" style={{ fontSize: '32px', marginBottom: '20px' }}>
                    There’s two ways to get started
                  </h2>

                  <div className="tagline">
                    Got plans for your next adventure? Don't know where to start? We've got you
                    covered! Our Travel Stylists have been working hard to build up a wide network
                    of connections to help you find the right fit at the best rate possible (plus
                    throw in some bonus VIP perks!). We're committed to helping you build the
                    perfect vacation, but to make that happen, we need a commitment from you as
                    well!
                  </div>
                </div>
              </div>

              <ChoosePlan onSelectPlan={onSelectPlan} isSignUpStep />
            </div>
          </div>
        </div>
      )}
    />
  );
};

ChoosePlanStep.propTypes = {
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default ChoosePlanStep;
