import React, { useContext, useState, useEffect } from 'react';

import apiClient from '@js/apiClient';
import { alert } from '@utils/Dialogs';
import { PLAN_A_TRIP_URL_WITH_PLANS } from '@jsv3/enums/urlEnums';
import EditFieldContext, { Editable } from '@jsv3/context/EditFieldContext';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import QuestionnaireWrapper from '@jsv3/components/organisms/QuestionnaireWrapper/QuestionnaireWrapper';
import { SURVEY_DISPLAY_TYPES } from '@jsv3/components/organisms/QuestionnaireWrapper/config';
import Spinner from '@jsv3/components/molecules/Spinner';

const GET_QUESTIONS_API_REQUEST = '/user/survey-question-list';

const { questionnaireFilters = {} } = window;

const QuizSection = ({ id }) => {
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { editableFields } = useContext(EditFieldContext);

  useEffect(() => {
    setIsLoading(true);

    apiClient
      .get(GET_QUESTIONS_API_REQUEST, {
        params: {
          type: questionnaireFilters.type,
        },
      })
      .then((response) => {
        setQuestions(response?.data?.surveyQuestions || []);
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  }, []);

  /**
   * @param answers
   */
  const goToPlans = (answers) => {
    setIsLoading(true);

    const base64Encoded = btoa(JSON.stringify(answers));

    window.location = `${PLAN_A_TRIP_URL_WITH_PLANS}?quizParams=${base64Encoded}`;
  };

  return (
    <section className="lp-quiz-section pt-50 pb-50" id={id}>
      <div className="small-container">
        <div className="section-title section-title--center section-title--with-underline section-title--mobile-left">
          <Editable fieldValue={editableFields.plan_trip_quiz_title} />
        </div>

        {editableFields.features_grid_description && (
          <div className="section-description section-description--center section-description--font-weigth-400 section-description--mobile-left fsz-20">
            <Editable fieldValue={editableFields.plan_trip_quiz_description} />
          </div>
        )}

        {isLoading && <Spinner />}

        {!isLoading && questions.length > 0 && (
          <QuestionnaireWrapper
            displayType={SURVEY_DISPLAY_TYPES.ONE_BY_ONE_WITH_IMAGE}
            questions={questions}
            scrollAfterSubmit={false}
            submitApiMethod="put"
            afterSubmit={goToPlans}
            classes="pr-0 pl-0 pt-50 pb-50"
            showQuestionImage
          />
        )}
      </div>
    </section>
  );
};
export default ErrorBoundaryDecorator()(QuizSection);
