import React, { useState, useContext, useRef, useEffect } from 'react';
import classNames from 'classnames';
import HTMLParser from 'html-react-parser';
import moment from 'moment';

import {
  updateFieldContent,
  updateFieldImage,
  deleteImageContent,
} from '@jsv2/utils/PageUtils/PageAPIRequests';
import {
  INITIAL_VALUE_KEY,
  FINAL_VALUE_KEY,
  WAS_RELOADED_KEY,
  IMAGES_TYPE,
  NOT_SET_VALUE,
  DEFAULT_VARIABLE_FIELD_VALUE,
  DEFAULT_IMAGE_FIELD_VALUE,
} from '@jsv2/Enums/EditableEnums';
import LPContext from '@jsv3/context/LPContext';
import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';
import ToggleSwitchCheckbox from '@jsv2/components/Common/ToggleSwitchCheckbox';
import createGeneralValueKey from '@jsv3/utils/helpers/createGeneralValueKeyLP';

const {
  location,
  templateVariables,
  templateImages,
  bypassAccessToken,
  isPreviewPage,
  pageId,
} = window;

const getProcessedTemplateFields = (fields, defaultValue) =>
  Object.keys(fields).reduce((result, fieldKey) => {
    if (!fields[fieldKey]) {
      result[fieldKey] = {
        name: fieldKey,
        ...defaultValue,
      };
    } else {
      result[fieldKey] = fields[fieldKey];
    }

    return result;
  }, {});

const queryString = location.search;
const urlParams = new URLSearchParams(queryString);

const EditFieldContext = React.createContext({});

export const EditFieldContextProvider = ({
  entityType = 'CMS\\BrandPage',
  entityId = pageId,
  children,
}) => {
  const [editableFields, setEditableFields] = useState(
    getProcessedTemplateFields(templateVariables, DEFAULT_VARIABLE_FIELD_VALUE),
  );
  const [editableImageFields, setEditableImageFields] = useState(
    getProcessedTemplateFields(templateImages, DEFAULT_IMAGE_FIELD_VALUE),
  );
  const [currentEditFieldKey, setCurrentEditFieldKey] = useState(null);
  const [currentEditField, setCurrentEditField] = useState(null);
  const [currentImageEditFieldKey, setCurrentImageEditFieldKey] = useState(null);
  const [currentImageEditField, setCurrentImageEditField] = useState(null);
  const [currentImageSize, setCurrentImageSize] = useState({ width: null, height: null });
  const [error, setError] = useState(null);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [isEditContentMode, setIsEditContentMode] = useState(isPreviewPage);

  const { isEditSectionMode, setIsEditSectionMode } = useContext(LPContext);

  /**
   * Create localStorage key for current field
   *
   * @return {string}
   */
  const currentItemLocalStorageKey = () => `${currentEditField?.name}--${currentEditField?.type}`;

  /**
   * Get all unsaved changes from localStorage
   *
   * @return {Array}
   */
  const getUnsavedChanges = () =>
    JSON.parse(localStorage.getItem(createGeneralValueKey(entityId))) || [];

  /**
   * Get unsaved changes item from localStorage by key
   *
   * @param fieldKey {String}
   * @return {Object}
   */
  const getUnsavedChangesItem = (fieldKey) =>
    getUnsavedChanges().find(({ key }) => key === fieldKey);

  /**
   * Set unsaved changes to localStorage
   *
   * @param updatedItems {Array}
   * @return {void}
   */
  const setUnsavedChanges = (updatedItems) => {
    try {
      if (updatedItems.length > 0) {
        localStorage.setItem(createGeneralValueKey(entityId), JSON.stringify(updatedItems));
      } else {
        localStorage.removeItem(createGeneralValueKey(entityId));
      }
    } catch (e) {
      Logger.error(e);
    }
  };

  /**
   * Create unsaved changes item
   *
   * @param key {String}
   * @param content {String || Object}
   * @param type {String}
   * @return {void}
   */
  const createUnsavedChangesItem = (key, content, type) => {
    const filteredItems = getUnsavedChanges().filter((item) => item.key !== key);
    const createdAt = moment().toDate().getTime();

    const newItem = {
      key,
      type,
      createdAt,
      [INITIAL_VALUE_KEY]: content,
      [FINAL_VALUE_KEY]: content,
      [WAS_RELOADED_KEY]: false,
    };

    const updatedItems = [...filteredItems, newItem];

    setUnsavedChanges(updatedItems);
  };

  /**
   * Clear item from localStorage
   *
   * @param fieldKey {String}
   * @return {Object}
   */
  const clearUnsavedChangesItem = (fieldKey) => {
    const items = getUnsavedChanges();

    if (items.length > 0) {
      const updatedItems = items.filter(({ key }) => key !== fieldKey);

      if (updatedItems.length > 0) {
        setUnsavedChanges(updatedItems);
      } else {
        localStorage.removeItem(createGeneralValueKey(entityId));
      }
    }
  };

  /**
   * Update text field state
   *
   * @param item {object}
   * @return {void}
   */
  const updateEditState = ({ content, name }) => {
    setEditableFields({
      ...editableFields,
      [name]: {
        ...editableFields[name],
        content,
      },
    });
  };

  /**
   * Update image field state
   *
   * @param file {Blob}
   * @param needToCreateUnsavedItem {Boolean}
   * @return {void}
   */
  const updateImageEditState = (file, needToCreateUnsavedItem = true) => {
    const oFReader = new FileReader();

    const loadFile = () => {
      oFReader.readAsDataURL(file);

      oFReader.onload = (oFREvent) => {
        const imageSrc = oFREvent.target.result;
        const key = `${currentImageEditField.name}--${currentImageEditField.type}`;

        if (needToCreateUnsavedItem) {
          createUnsavedChangesItem(key, imageSrc, IMAGES_TYPE);
        }

        setEditableImageFields((prev) => ({
          ...prev,
          [currentImageEditFieldKey]: {
            ...prev[currentImageEditFieldKey],
            content: {
              ...prev[currentImageEditFieldKey].content,
              path: imageSrc,
            },
          },
        }));
      };
    };

    loadFile();
  };

  /**
   * Handle text field edit popup
   *
   * @param item {object}
   * @return {void}
   */
  const handleEditPopup = (item) => {
    if (!isEditLoading) {
      setCurrentEditField(item);
      setCurrentEditFieldKey(item.name);

      setCurrentImageEditFieldKey(null);
      setCurrentImageEditField(null);
    }
  };

  /**
   * Handle image field edit popup
   *
   * @param item {object}
   * @param size {object}
   * @return {void}
   */
  const handleImageEditPopup = (item, size) => {
    setCurrentImageEditField(item);
    setCurrentImageEditFieldKey(item.name);
    setCurrentImageSize(size);

    setCurrentEditFieldKey(null);
    setCurrentEditField(null);
  };

  /**
   * Close popup
   *
   * @return {void}
   */
  const closeEditPopup = () => {
    setCurrentEditFieldKey(null);
    setCurrentEditField(null);
    setCurrentImageEditFieldKey(null);
    setCurrentImageEditField(null);
  };

  /**
   * Save text field
   *
   * @param currentField {any}
   * @return {void}
   */
  const saveField = (currentField) => {
    setIsEditLoading(true);

    updateFieldContent(currentField, urlParams.get('access_token'), entityType, entityId)
      .then(() => {
        closeEditPopup();
      })
      .catch((currentError) => {
        setError('Sorry, some error occurred. Please try again later.');

        Logger.error(t(currentError.response.message));
      })
      .finally(() => {
        setIsEditLoading(false);
      });
  };

  /**
   * Save image field
   *
   * @param data {any}
   * @return {void}
   */
  const saveImageField = (data) => {
    setIsEditLoading(true);

    const { width, height } = currentImageSize;

    updateFieldImage(
      currentImageEditField,
      data,
      { width, height },
      urlParams.get('access_token'),
      entityType,
      entityId,
    )
      .then(() => {
        setIsEditLoading(false);
        setError(null);

        closeEditPopup();

        setTimeout(() => {
          updateImageEditState(data, false);
        });
      })
      .catch(() => {
        setError('Please, upload a valid image file.');
      })
      .finally(() => {
        setIsEditLoading(false);
      });
  };

  /**
   * Delete image
   *
   * @param {object} field
   */
  const purgeFieldContent = (field) => {
    deleteImageContent(entityId, entityType, field, urlParams.get('access_token')).then(() => {
      setTimeout(() => {
        setEditableImageFields((prev) => ({
          ...prev,
          [field.name]: {
            ...prev[field.name],
            content: null,
          },
        }));
      });
    });
  };

  /**
   * Reset text edit field
   *
   * @param content {string}
   * @param needToClose {boolean}
   *
   * @return {void}
   */
  const resetEditField = (content, needToClose = true) => {
    setEditableFields((prevState) => ({
      ...prevState,
      [currentEditFieldKey]: {
        ...prevState[currentEditFieldKey],
        content,
      },
    }));

    if (needToClose) {
      setCurrentEditFieldKey(null);
      setCurrentEditField(null);
    }
  };

  /**
   * Reset image edit field
   *
   * @return {void}
   */
  const resetEditImageField = (needToClose = true) => {
    setEditableImageFields((prev) => ({
      ...prev,
      [currentImageEditFieldKey]: {
        ...prev[currentImageEditFieldKey],
        content: currentImageEditField.content,
      },
    }));

    if (needToClose) {
      setTimeout(() => {
        setCurrentImageEditFieldKey(null);
        setCurrentImageEditField(null);
      });
    }
  };

  /**
   * Check if content is empty
   * @param fieldValueContent {String}
   *
   * @return {Boolean}
   */
  const isFieldValueContentEmpty = (fieldValueContent) => {
    if (!fieldValueContent) {
      return true;
    }

    const el = document.createElement('div');
    el.innerHTML = fieldValueContent;

    const content = el.innerText.trim();

    return content.length === 0;
  };

  /**
   * Render "warning preview ribbon"
   *
   * @return {JSXElement}
   */
  const renderWarningPreviewRibbon = () => {
    const link = `${location.href.split('?')[0]}?access_token=${bypassAccessToken}`;

    return (
      <div className="preview-page-ribbon">
        <div className="navigation-block-ribbon">
          <ToggleSwitchCheckbox
            id="edit-content-mode"
            label="Edit content mode"
            onChange={() => setIsEditContentMode((prevState) => !prevState)}
            checked={isEditContentMode}
          />

          {isEditSectionMode !== undefined && (
            <ToggleSwitchCheckbox
              id="edit-section-mode"
              label="Edit section mode"
              onChange={() => setIsEditSectionMode((prevState) => !prevState)}
              checked={isEditSectionMode}
            />
          )}
        </div>

        <div className="is-preview-info-block-ribbon">
          Page is opened in &lsquo;preview mode&rsquo;
          {bypassAccessToken && (
            <>
              , <br />
              click{' '}
              <strong>
                <a href={link} target="_blank" rel="noopener noreferrer">
                  here
                </a>
              </strong>{' '}
              to see public version.
            </>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const currentTimestamp = moment().toDate().getTime();

    const filteredItems = getUnsavedChanges().filter(({ createdAt }) => {
      if (typeof createdAt !== 'number') {
        return false;
      }

      const expirationTimestamp = moment(createdAt).add('1', 'hour').toDate().getTime();

      return expirationTimestamp > currentTimestamp;
    });

    setUnsavedChanges(filteredItems);
  }, []);

  return (
    <EditFieldContext.Provider
      value={{
        pageId: entityId,
        isEditable: isEditContentMode,
        editableFields,
        editableImageFields,
        currentEditField,
        currentEditFieldKey,
        setCurrentEditFieldKey,
        currentImageEditField,
        handleEditPopup,
        handleImageEditPopup,
        closeEditPopup,
        updateEditState,
        saveField,
        saveImageField,
        purgeFieldContent,
        isEditLoading,
        setIsEditLoading,
        error,
        setError,
        resetEditField,
        resetEditImageField,
        updateImageEditState,
        getUnsavedChanges,
        getUnsavedChangesItem,
        setUnsavedChanges,
        clearUnsavedChangesItem,
        createUnsavedChangesItem,
        isFieldValueContentEmpty,
        currentItemLocalStorageKey,
      }}
    >
      {children}

      {isPreviewPage && renderWarningPreviewRibbon()}
    </EditFieldContext.Provider>
  );
};

export const WithEditFieldContextProvider = (Component) => (props) => (
  <EditFieldContextProvider>
    <Component {...props} />
  </EditFieldContextProvider>
);

export const Editable = ({ fieldValue = { content: '' }, isOptionalField = false }) => {
  const { isEditable, handleEditPopup, isEditLoading, isFieldValueContentEmpty } = useContext(
    EditFieldContext,
  );
  const screenTypeContext = useContext(ScreenTypeContext);

  const editableWrapperRef = useRef(null);

  const boxClassName = classNames('content-block editable_box', {
    'no-content': isFieldValueContentEmpty(fieldValue?.content),
  });

  const wrapperClassName = classNames('editable_wrapper', {
    'edit-field-is-loading': isEditLoading,
  });

  const convertInlineStyleToClass = (stylesToConvert) => {
    const configToConvert = [
      { key: 'ql-align-', match: /text-align:\s?(.*);/ },
      { key: 'ql-size-', match: /font-size:\s?(\d{1,2})px;/ },
      { key: 'ql-font-', match: /font-family:\s?(.*);/ },
    ];

    let res = '';

    const arrOfStyles = stylesToConvert.split(';').map((style) => style + ';');

    arrOfStyles.forEach((styleToConvert) => {
      const { key, match } =
        configToConvert.find((config) => styleToConvert.match(config.match)) || {};

      if (key) {
        const matchedValue = styleToConvert.match(match) || [];
        let value = matchedValue.length > 0 ? matchedValue[1].split(',')[0] : '';

        if (!value && !res.includes('ql-align-')) {
          value = 'left';
        }

        if (value) {
          res += `${key}${value} `;
        }
      }
    });

    return res.trim();
  };

  const convertInlineStylesToClasses = (contentToConvert) => {
    const configToConvert = {
      fromStyle: /style="[^\\"]*(\\"[^\\"]*)*"/g,
      toStyle: 'class',
    };

    const { fromStyle, toStyle } = configToConvert;

    const arrOfStyles = contentToConvert.match(fromStyle) || [];

    let res = contentToConvert;

    arrOfStyles.forEach((style) => {
      const convertedStyle = `${toStyle}="${convertInlineStyleToClass(style)}"`;

      res = res.replace(style, convertedStyle);
    });

    return res;
  };

  const checkResponsiveFontSize = (content) => {
    let value = content;

    if (isMobile(screenTypeContext)) {
      const fontSizes = value.match(/ql-size-(\d{1,3})/g) || [];

      fontSizes.forEach((fontSizeStr) => {
        const fontSize = fontSizeStr.match(/\d+/)[0];

        if (fontSize === 20) {
          value = value.replace(`ql-size-${fontSize}`, 'ql-size-18');
        } else if (fontSize > 35) {
          value = value.replace(`ql-size-${fontSize}`, 'ql-size-35');
        }
      });
    }

    return value;
  };

  const convertComputedStylesToClasses = (computedStyles, fieldValueContent) => {
    const configToConvert = [
      { inlineKey: 'text-decoration' },
      { inlineKey: 'text-weight' },
      { inlineKey: 'text-align', classKey: 'ql-align-' },
      { inlineKey: 'font-size', classKey: 'ql-size-' },
      { inlineKey: 'font-family', classKey: 'ql-font-' },
    ];

    let styledContent = fieldValueContent;

    let elementClasses = '';
    let additionalClasses = '';

    configToConvert.forEach(({ inlineKey, classKey }) => {
      const style = computedStyles[inlineKey];

      if (inlineKey === 'font-size') {
        const fontSize = style.match(/\d+/)[0];

        additionalClasses += `${classKey}${fontSize} `;
      } else if (inlineKey === 'font-family') {
        const fontFamily = style.split(',')[0];

        additionalClasses += `${classKey}${fontFamily} `;
      } else if (inlineKey === 'font-weight' && style === '700') {
        styledContent = `<strong>${styledContent}</strong>`;
      } else if (inlineKey === 'text-decoration' && style.includes('underline')) {
        styledContent = `<u>${styledContent}</u>`;
      } else {
        elementClasses += `${classKey}${style} `;
      }
    });

    return {
      styledContent,
      elementClasses: elementClasses.trim(),
      additionalClasses: additionalClasses.trim(),
    };
  };

  const processedContent = () => {
    const { content } = fieldValue || {};

    if (isFieldValueContentEmpty(content)) {
      return isEditable ? NOT_SET_VALUE : null;
    }

    let value = content;

    value = convertInlineStylesToClasses(value);
    value = checkResponsiveFontSize(value);

    return value;
  };

  const renderInactiveEditableContent = () => {
    const content = processedContent();

    return content && <span className="inactive-editable-content">{HTMLParser(content)}</span>;
  };

  const onClickHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!isEditLoading) {
      const fieldValueContent = processedContent();

      if (typeof HTMLParser(fieldValueContent) === 'string') {
        const computedStyles = getComputedStyle(editableWrapperRef.current.parentElement);

        const { styledContent, elementClasses, additionalClasses } = convertComputedStylesToClasses(
          computedStyles,
          fieldValueContent,
        );

        const processedFieldContent = `
           <p class="${elementClasses}">
            <span class="${additionalClasses}">${styledContent}</span>
           </p>
          `;

        handleEditPopup({
          ...fieldValue,
          content: processedFieldContent,
        });
      } else {
        handleEditPopup({
          ...fieldValue,
          content: fieldValueContent,
        });
      }
    }
  };

  if (isEditable) {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div className={wrapperClassName} onClick={onClickHandler} ref={editableWrapperRef}>
        <div className="editable_hint">
          <div className="editable_hint__left">
            <img className="editable_icon" src="/images/icons/text-icon.svg" alt="text_edit_icon" />

            <span>Text</span>

            {!isOptionalField && <span className="editable_hint__asterisk">*</span>}
          </div>

          <div className="editable_hint__right">
            <img
              className="editable_icon"
              src="/images/icons/pencil-white.svg"
              alt="text_edit_icon"
            />
          </div>
        </div>

        <div className={boxClassName}>
          <div className="editable_content" data-qa-id="qa_editable_content">
            {HTMLParser(processedContent())}
          </div>
        </div>
      </div>
    );
  }

  return renderInactiveEditableContent();
};

export const EditableImg = ({ image, width, height, children }) => {
  const { isEditable, handleImageEditPopup, isEditLoading, purgeFieldContent } = useContext(
    EditFieldContext,
  );

  const wrapperClassName = classNames('editable_wrapper', {
    'edit-field-is-loading': isEditLoading,
  });

  const isImageContent = image.content && Object.keys(image.content).length > 0;

  const deleteImage = () => {
    if (!isEditLoading) {
      purgeFieldContent(image);
    }
  };

  const handler = () => {
    if (!isEditLoading) {
      handleImageEditPopup(image, { width, height });
    }
  };

  if (isEditable) {
    return (
      <div className={wrapperClassName}>
        <div className="editable_hint">
          <div className="editable_hint__left">
            <img
              className="editable_icon"
              src="/images/icons/picture-icon.svg"
              alt="image_edit_icon"
            />

            <span>Image</span>
          </div>

          <div className="editable_hint__right">
            <img
              className="editable_icon"
              src="/images/icons/setting-icon.svg"
              alt="image_edit_icon"
            />
          </div>

          {isImageContent && (
            <button type="button" onClick={deleteImage} className="editable_hint__delete-btn">
              <img
                className="editable_icon"
                src="/images/icons/delete.svg"
                alt="image_delete_icon"
              />
              Delete image
            </button>
          )}
        </div>

        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className={`content-block ${!isImageContent ? 'content-block--no-img' : ''}`}
          onClick={handler}
        >
          {isImageContent && children}
        </div>
      </div>
    );
  }

  return image.content && children;
};

export default EditFieldContext;
