import React, { useContext } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv3/context/EditFieldContext';

const TripBannerSection = React.forwardRef(({ id }, ref) => {
  const { editableFields } = useContext(EditFieldContext);

  return (
    <>
      {editableFields && (
        <section className="trip-banner" id={id} ref={ref}>
          <div className="main-container">
            <div className="common-title common-title--large white-text text-center mb-30 calluna-font">
              <Editable fieldValue={editableFields.trip_banner_tagline} />
            </div>

            <div className="section-title section-title--white section-title--small section-title--center futura-font mb-5">
              <Editable fieldValue={editableFields.trip_banner_title} />
            </div>

            <div className="common-title white-text text-center">
              <Editable fieldValue={editableFields.trip_banner_price} />
            </div>
          </div>
        </section>
      )}
    </>
  );
});

TripBannerSection.displayName = 'TripBannerSection';

export default ErrorBoundaryDecorator()(TripBannerSection);
