import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import LPContext from '@jsv3/context/LPContext';
import EditFieldContext, { Editable } from '@jsv3/context/EditFieldContext';

const ResearchScheme = React.forwardRef(
  ({ id, onRequestCloseModal, showBecomeButton, showTotal }, ref) => {
    const { editableFields } = useContext(EditFieldContext);
    const { renderBecomeButton } = useContext(LPContext);

    return (
      <>
        {editableFields && (
          <section className="schema schema--popup" ref={ref} id={id}>
            <div className="main-container schema__container">
              <h2 className="section-title section-title--center">
                <Editable fieldValue={editableFields.stylist_in_action_heading} />
              </h2>

              <div className="schema__description">
                <Editable fieldValue={editableFields.stylist_in_action_testimonial} />
              </div>

              <div className="schema__view">
                <div className="schema__row schema__top-row">
                  <div className="schema__top-col schema__top-col--diy">
                    <div className="schema__col-name">{t('DO IT YOURSELF')}</div>
                    <div className="schema__col-icon schema__col-icon--diy" />
                  </div>

                  <div className="schema__col-center">
                    <div className="schema__ex-text schema__ex-text--bold">
                      <Editable fieldValue={editableFields.example_title} />
                    </div>

                    <div className="schema__example">
                      <div className="schema__ex-text schema__ex-text--red">
                        <Editable fieldValue={editableFields.example_line_1} />
                      </div>

                      <div className="schema__ex-text schema__ex-text--red">
                        <Editable fieldValue={editableFields.example_line_2} />
                      </div>

                      {/* <div className="schema__ex-text schema__ex-text--red">
                      <Editable fieldValue={editableFields.example_line_3} />
                    </div> */}
                    </div>
                  </div>

                  <div className="schema__top-col schema__top-col--vip">
                    <div className="schema__col-name schema__col-name--vip" />

                    <div className="schema__col-icon schema__col-icon--vip" />
                  </div>
                </div>

                <div className="schema__row schema__lines-row">
                  <div className="schema__col schema__lines-col schema__lines-col--diy">
                    <div className="schema__step schema__step--diy schema__step--diy-first">
                      <Editable fieldValue={editableFields.diy_row_1_title} />
                    </div>
                    <div className="schema__step schema__step--diy schema__step--diy-second">
                      <Editable fieldValue={editableFields.diy_row_2_title} />
                    </div>
                    <div className="schema__step schema__step--diy schema__step--diy-third">
                      <Editable fieldValue={editableFields.diy_row_3_title} />
                    </div>
                    <div className="schema__step schema__step--diy schema__step--diy-fourth">
                      <Editable fieldValue={editableFields.diy_row_4_title} />
                    </div>
                    <div className="schema__step schema__step--check-out schema__step--check-out-diy">
                      <Editable fieldValue={editableFields.diy_row_5_title} />
                    </div>
                  </div>

                  <div className="schema__col schema__lines-col schema__lines-col--vip">
                    <div className="schema__step schema__step--vip schema__step--vip-first">
                      <Editable fieldValue={editableFields.vip_row_1_title} />
                    </div>
                    <div className="schema__step schema__step--vip schema__step--vip-second">
                      <Editable fieldValue={editableFields.vip_row_2_title} />
                    </div>
                    <div className="schema__step schema__step--vip schema__step--vip-third">
                      <Editable fieldValue={editableFields.vip_row_3_title} />
                    </div>
                    <div className="schema__step schema__step--vip schema__step--vip-fourth">
                      <Editable fieldValue={editableFields.vip_row_4_title} />
                    </div>
                    <div className="schema__step schema__step--vip schema__step--vip-fifth">
                      <Editable fieldValue={editableFields.vip_row_5_title} />
                    </div>
                    <div className="schema__step schema__step--check-out schema__step--check-out-vip">
                      <Editable fieldValue={editableFields.vip_row_6_title} />
                    </div>
                  </div>
                </div>

                {showTotal && (
                  <div className="schema__row schema__prices-row">
                    <div className="schema__col schema__prices-col">
                      <div className="schema__price">
                        <Editable fieldValue={editableFields.diy_total_title} />
                      </div>
                    </div>

                    <div className="schema__col schema__prices-col">
                      <div className="schema__price schema__price--red">
                        <Editable fieldValue={editableFields.vip_total_title} />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {showBecomeButton &&
                renderBecomeButton(
                  'become-btn become-btn--with-border',
                  window.templateVariables.body_cta_text.content,
                  'become-join-btn-3',
                  onRequestCloseModal,
                )}
            </div>
          </section>
        )}
      </>
    );
  },
);

ResearchScheme.propTypes = {
  id: PropTypes.string,
  showTotal: PropTypes.bool,
  onRequestCloseModal: PropTypes.func,
  showBecomeButton: PropTypes.bool,
};

ResearchScheme.defaultProps = {
  id: '',
  onRequestCloseModal: () => {},
  showBecomeButton: true,
  showTotal: true,
};
export default ErrorBoundaryDecorator()(ResearchScheme);
