import React, { useState, useContext, useEffect, useLayoutEffect, useRef } from 'react';

import Spinner from '@components/Spinner';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

import {
  INITIAL_VALUE_KEY,
  FINAL_VALUE_KEY,
  WAS_RELOADED_KEY,
  VARIABLES_TYPE,
  IMAGES_TYPE,
} from '@jsv2/Enums/EditableEnums';

import EditFieldContext from '@jsv3/context/EditFieldContext';
import createGeneralValueKey from '@jsv3/utils/helpers/createGeneralValueKeyLP';
import StyleTextEditor from './UI/StyleTextEditor';

const EditPopup = () => {
  const {
    pageId,
    isEditable,
    error,
    setError,
    currentEditField,
    currentEditFieldKey,
    updateEditState,
    saveField,
    resetEditField,
    isEditLoading,
    getUnsavedChanges,
    getUnsavedChangesItem,
    setUnsavedChanges,
    clearUnsavedChangesItem,
    createUnsavedChangesItem,
    currentItemLocalStorageKey,
  } = useContext(EditFieldContext);

  const [fieldValue, setFieldValue] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [needToCreateEditorState, setNeedToCreateEditorState] = useState(false);

  const initialFieldValue = useRef(null);

  const onHandleSubmit = (field) => {
    clearUnsavedChangesItem(currentItemLocalStorageKey());

    initialFieldValue.current = { ...field, needToClose: true };

    saveField(field);
  };

  const onCancelChanges = () => {
    clearUnsavedChangesItem(currentItemLocalStorageKey());

    resetEditField(initialFieldValue.current.content);
  };

  const convertForClearCheck = (content) => content.split('').sort().join('');

  const onHandleFieldChange = (content) => {
    setFieldValue({ ...fieldValue, content });
    updateEditState({ ...fieldValue, content });

    setError(null);

    const currentItemKey = currentItemLocalStorageKey();
    const currentItem = getUnsavedChangesItem(currentItemKey);

    if (currentItem) {
      setHasUnsavedChanges(currentItem[WAS_RELOADED_KEY]);

      if (convertForClearCheck(currentItem[INITIAL_VALUE_KEY]) !== convertForClearCheck(content)) {
        const updatedItem = {
          ...currentItem,
          [FINAL_VALUE_KEY]: content,
          [WAS_RELOADED_KEY]: false,
        };

        const updatedItems = getUnsavedChanges().map((item) => {
          if (item.key === currentItemKey) {
            return updatedItem;
          }

          return item;
        });

        setUnsavedChanges(updatedItems);
      }
    } else {
      createUnsavedChangesItem(currentItemKey, content, VARIABLES_TYPE);
      setHasUnsavedChanges(false);
    }
  };

  const onRestoreUnsavedChanges = () => {
    const currentItem = getUnsavedChangesItem(currentItemLocalStorageKey());
    const content = currentItem[FINAL_VALUE_KEY];

    const updatedItems = getUnsavedChanges().map((item) => {
      if (item.key === currentItem.key) {
        return { ...item, [WAS_RELOADED_KEY]: false };
      }

      return item;
    });

    setHasUnsavedChanges(false);
    setNeedToCreateEditorState(true);

    setUnsavedChanges(updatedItems);

    onHandleFieldChange(content);
  };

  const renderTextEditForm = () => (
    <div className="editable-popup" data-qa-id="qa_editable_popup">
      {isEditLoading ? (
        <Spinner />
      ) : (
        <div className="editable-form">
          <StyleTextEditor
            fieldValue={fieldValue}
            onHandleFieldChange={onHandleFieldChange}
            needToCreateEditorState={needToCreateEditorState}
            setNeedToCreateEditorState={setNeedToCreateEditorState}
          />

          <div
            className={`editable-form__bottom-container${
              hasUnsavedChanges ? ' _hasUnsavedChanges' : ''
            }`}
          >
            {hasUnsavedChanges && !error && (
              <div className="editable-form__unsaved-changes">
                you have unsaved changes,
                <button type="button" onClick={onRestoreUnsavedChanges}>
                  restore?
                </button>
              </div>
            )}

            <div className="editable-form__btns">
              <button
                type="button"
                className="editable-btn editable-btn--save"
                onClick={() => onHandleSubmit(fieldValue)}
                data-qa-id="qa_save_btn"
              >
                {t('Save')}
              </button>

              <button
                type="button"
                className="editable-btn editable-btn--reset"
                onClick={onCancelChanges}
                data-qa-id="qa_cancel_btn"
              >
                {t('Cancel')}
              </button>
            </div>
          </div>

          {error && <p className="editable-form__error">{error}</p>}
        </div>
      )}
    </div>
  );

  useEffect(() => {
    setError(null);

    setFieldValue(currentEditField);
    initialFieldValue.current = currentEditField;
  }, [currentEditField, isEditable]);

  useLayoutEffect(
    () => () => {
      if (currentEditFieldKey) {
        const { content, needToClose } = initialFieldValue.current;

        resetEditField(content, !!needToClose);

        if (needToClose) {
          initialFieldValue.current = null;
        }
      }

      const items = getUnsavedChanges();

      if (items.length > 0) {
        const updatedAllItems = items.map((item) => {
          if (!item[WAS_RELOADED_KEY]) {
            return { ...item, [WAS_RELOADED_KEY]: true };
          }

          return item;
        });

        const variableItems = updatedAllItems.filter((item) => item.type === VARIABLES_TYPE);

        const filteredVariableItems = variableItems.filter(
          (item) => item[INITIAL_VALUE_KEY] !== item[FINAL_VALUE_KEY],
        );

        const imagesItems = updatedAllItems.filter((item) => item.type === IMAGES_TYPE);

        if (filteredVariableItems.length > 0 || imagesItems.length > 0) {
          setUnsavedChanges([...imagesItems, ...filteredVariableItems]);
        } else if (filteredVariableItems.length === 0 && imagesItems.length === 0) {
          localStorage.removeItem(createGeneralValueKey(pageId));
        }
      }
    },
    [currentEditFieldKey],
  );

  return <>{initialFieldValue.current && isEditable && renderTextEditForm()}</>;
};

export default ErrorBoundaryDecorator()(EditPopup);
