import React, { useContext } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import LPContext from '@jsv3/context/LPContext';
import EditFieldContext, { Editable } from '@jsv3/context/EditFieldContext';

const LeftContentInSection = React.forwardRef(
  (
    {
      id,
      classes = '',
      childrenClasses = '',
      heading,
      testimonial,
      firstColTitle = null,
      secondColTitle = null,
      firstColContent = null,
      secondColContent = null,
      children,
    },
    ref,
  ) => {
    const { editableFields } = useContext(EditFieldContext);
    const { renderBecomeButton } = useContext(LPContext);

    return (
      <>
        {editableFields && (
          <section className={`left-content-component ${classes}`} id={id} ref={ref}>
            <div className="small-container">
              <div className="left-content-component__wrapper pt-50 pb-50">
                <div className="left-content-component__info">
                  <div className="left-content-component__info-wrapper">
                    <div className="section-title section-title--with-underline">
                      <Editable fieldValue={heading} />
                    </div>

                    <div className="common-text">
                      <Editable fieldValue={testimonial} />
                    </div>

                    {firstColTitle && (
                      <div className="left-content-component__list">
                        <div className="left-content-component__list-item">
                          <div className="common-title">
                            <Editable fieldValue={firstColTitle} />
                          </div>

                          <div className="common-text">
                            <Editable fieldValue={firstColContent} />
                          </div>
                        </div>

                        <div className="left-content-component__list-item">
                          <div className="common-title">
                            <Editable fieldValue={secondColTitle} />
                          </div>

                          <div className="common-text">
                            <Editable fieldValue={secondColContent} />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="left-content-component__btn left-content-component__btn--desktop">
                      {renderBecomeButton(
                        'become-btn become-btn--red become-btn--border-radius w-100 max-w-100 ',
                        window.templateVariables.body_cta_text.content,
                        `become-join-btn-${id}`,
                      )}
                    </div>
                  </div>
                </div>

                <div className={`left-content-component__children ${childrenClasses}`}>
                  {children}
                </div>

                <div className="left-content-component__btn left-content-component__btn--mobile">
                  {renderBecomeButton(
                    'become-btn become-btn--red become-btn--border-radius w-100 max-w-100 ',
                    window.templateVariables.body_cta_text.content,
                    `become-join-btn-${id}`,
                  )}
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    );
  },
);

export default ErrorBoundaryDecorator()(LeftContentInSection);
