import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv3/context/EditFieldContext';
import LeftContentInSection from '../LeftContentInSection';

const ContentWithChatSection = ({ id, className }) => {
  const { editableFields } = useContext(EditFieldContext);

  return (
    <LeftContentInSection
      id={id}
      classes={`chat-preview ${className}`}
      heading={editableFields.redesign_section_2_heading}
      testimonial={editableFields.redesign_section_2_testimonial}
      firstColTitle={editableFields.redesign_section_2_col_1_title}
      secondColTitle={editableFields.redesign_section_2_col_2_title}
      firstColContent={editableFields.redesign_section_2_col_1_content}
      secondColContent={editableFields.redesign_section_2_col_2_content}
    >
      <div className="stylists__image-wrapper">
        <div className="stylists__icon stylists__icon--whatsapp" />

        <div className="stylists__icon stylists__icon--phone" />

        <div className="stylists__icon stylists__icon--email" />

        <div className="stylists__icon stylists__icon--msg stylists__icon--msg-border" />

        <div className="stylists__image">
          <div className="stylists__msg stylists__msg--left">
            <div className="stylists__msg-avatar stylists__msg-avatar--left" />
            <div className="stylists__msg-text stylists__msg-text--gray">
              <Editable fieldValue={editableFields.message_1} />
            </div>
          </div>

          <div className="stylists__msg stylists__msg--right">
            <div className="stylists__msg-avatar stylists__msg-avatar--right" />
            <div className="stylists__msg-text stylists__msg-text--pink">
              <Editable fieldValue={editableFields.message_2} />
            </div>
          </div>

          <div className="stylists__msg stylists__msg--left">
            <div className="stylists__msg-avatar stylists__msg-avatar--left" />
            <div className="stylists__msg-text stylists__msg-text--gray">
              <Editable fieldValue={editableFields.message_3} />
            </div>
          </div>

          <div className="stylists__msg stylists__msg--right">
            <div className="stylists__msg-avatar stylists__msg-avatar--right" />
            <div className="stylists__msg-text stylists__msg-text--pink stylists__msg-text--dots" />
          </div>
        </div>
      </div>
    </LeftContentInSection>
  );
};

ContentWithChatSection.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ContentWithChatSection.defaultProps = {
  className: '',
};

export default ErrorBoundaryDecorator()(ContentWithChatSection);
