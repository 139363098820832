import React, { useContext } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv3/context/EditFieldContext';
import SliderWithBorder from './SliderWithBorder';

const TechnologySection = ({ id }) => {
  const { editableFields, editableImageFields } = useContext(EditFieldContext);

  const sliderItems = [
    {
      id: 1,
      image: editableImageFields.technology,
    },
    {
      id: 2,
      image: editableImageFields.technology_2,
    },
    {
      id: 3,
      image: editableImageFields.technology_3,
    },
  ];

  return (
    <>
      {editableFields && (
        <section className="technology travel-section" id={id}>
          <div className="main-container">
            <div className="travel-section__wrapper">
              <div className="travel-section__info">
                <div className="travel-section__info-wrapper">
                  <div className="section-title section-title--with-underline">
                    <Editable fieldValue={editableFields.technology_heading} />
                  </div>

                  <div className="section-description">
                    <Editable fieldValue={editableFields.technology_testimonial} />
                  </div>

                  <div className="travel-section__list">
                    <div className="travel-section__item">
                      <div className="common-title">
                        <Editable fieldValue={editableFields.technology_finds_title} />
                      </div>

                      <div className="common-text">
                        <Editable fieldValue={editableFields.technology_finds_content} />
                      </div>
                    </div>

                    <div className="travel-section__item">
                      <div className="common-title">
                        <Editable fieldValue={editableFields.technology_search_title} />
                      </div>

                      <div className="common-text">
                        <Editable fieldValue={editableFields.technology_search_content} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <SliderWithBorder items={sliderItems} />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ErrorBoundaryDecorator()(TechnologySection);
