import SectionWithAdditionalContentHOC from '@jsv2/utils/SectionWithAdditionalContentHOC';
import TechnologySection from './UI/Sections/TechnologySection';
import ChatSection from './UI/Sections/ChatSection';
import ExclusiveOffersSection, { ExclusiveOffersImage } from './UI/Sections/ExclusiveOffersSection';
import BecomeSection from './UI/Sections/BecomeSection';
import VideosSectionWide from './UI/Sections/VideosSectionWide';
import WhySection from './UI/Sections/WhySection';
import About from './UI/Sections/AboutSection';
import AboutWithTwoRow from './UI/Sections/AboutSectionWithTwoRow';
import Banner from './UI/Sections/BannerSection';
import BannerWithAnimation from './UI/Sections/BannerSectionWithAnimation';
import ResearchScheme from './UI/Sections/ResearchScheme';
import HowItWorks from './UI/Sections/HowItWorks';
import VideosSection from './UI/Sections/VideosSection';
import Reviews from './UI/Sections/Reviews';
import PubsSection from './UI/Sections/PubsSection';
import OffersSliderWithReviews from './UI/Sections/OffersSliderWithReviews';
import OffersSliderWithBenefits from './UI/Sections/OffersSliderWithBenefits';
import BenefitsSection from './UI/Sections/BenefitsSection';
import FreeUpSection from './UI/Sections/FreeUpSection';
import ReviewsWithAvatar from './UI/Sections/ReviewsWithAvatar';
import ChoosePlanSection from './UI/Sections/ChoosePlanSection';
import BlogSection from './UI/Sections/BlogSection';
import TrustFactors from './UI/Sections/TrustFactors';
import BlogSectionGridView from './UI/Sections/BlogSectionGridView';
import TeamSection from './UI/Sections/TeamSection';
import DestinationExpertsSection from './UI/Sections/DestinationExpertsSection';
import Testimonial from './UI/Sections/Testimonial';
import ContentGridSection from './UI/Sections/ContentGridSection';
import TripBannerSection from './UI/Sections/TripBannerSection';
import ReviewsWithBorder from './UI/Sections/ReviewsWithBorder';
import FaqsSection from './UI/Sections/FaqsSection';
import ContentSection from './UI/Sections/ContentSection';
import SimpleBanner from './UI/Sections/SimpleBanner';
import LargeBenefitsSection from './UI/Sections/LargeBenefitsSection';
import SingleImage from './UI/Sections/SingleImage';
import HowStartedWithGallery from './UI/Sections/HowStartedWithGallery';
import Quote from './UI/Sections/Quote';
import ContentWithBenefitsSection from './UI/Sections/ContentWithBenefitsSection';
import ContentWithChatSection from './UI/Sections/ContentWithChatSection';
import ContentWithOffersSection from './UI/Sections/ContentWithOffersSection';
import RedesignChoosePlanSection from './UI/Sections/RedesignChoosePlanSection';
import FeaturesGrid from './UI/Sections/FeaturesGrid';
import FullWidthImage from './UI/Sections/FullWidthImage';
import QuizSection from './UI/Sections/QuizSection';
import ReviewsWithVideo from './UI/Sections/ReviewsWithVideo';

export default {
  banner: Banner,
  bannerWithAnimation: BannerWithAnimation,
  about: About,
  aboutWithTwoRow: AboutWithTwoRow,
  pubsSection: PubsSection,
  whySection: WhySection,
  videosSectionWide: VideosSectionWide,
  technologySection: TechnologySection,
  chatSection: ChatSection,
  exclusiveOffersWithImageSection: SectionWithAdditionalContentHOC(
    ExclusiveOffersSection,
    ExclusiveOffersImage,
  ),
  exclusiveOffersWithSliderBenefitsSection: SectionWithAdditionalContentHOC(
    ExclusiveOffersSection,
    OffersSliderWithBenefits,
  ),
  exclusiveOffersWithSliderReviewsSection: SectionWithAdditionalContentHOC(
    ExclusiveOffersSection,
    OffersSliderWithReviews,
  ),
  howItWorks: HowItWorks,
  reviews: Reviews,
  becomeSection: BecomeSection,
  researchScheme: ResearchScheme,
  videosSection: VideosSection,
  offersSliderWithReviews: OffersSliderWithReviews,
  offersSliderWithBenefits: OffersSliderWithBenefits,
  benefitsSection: BenefitsSection,
  freeUpSection: FreeUpSection,
  reviewsWithAvatar: ReviewsWithAvatar,
  reviewsWithVideo: ReviewsWithVideo,
  choosePlanSection: ChoosePlanSection,
  blogSection: BlogSection,
  blogSectionGridView: BlogSectionGridView,
  trustFactors: TrustFactors,
  teamSection: TeamSection,
  destinationExpertsSection: DestinationExpertsSection,
  testimonial: Testimonial,
  contentGrid: ContentGridSection,
  tripBannerSection: TripBannerSection,
  reviewsWithBorder: ReviewsWithBorder,
  faqs: FaqsSection,
  contentSection: ContentSection,
  simpleBanner: SimpleBanner,
  largeBenefitsSection: LargeBenefitsSection,
  singleImage: SingleImage,
  howStartedWithGallery: HowStartedWithGallery,
  quote: Quote,
  contentWithBenefits: ContentWithBenefitsSection,
  contentWithChat: ContentWithChatSection,
  contentWithOffers: ContentWithOffersSection,
  redesignChoosePlan: RedesignChoosePlanSection,
  featuresGrid: FeaturesGrid,
  fullWidthImage: FullWidthImage,
  quizSection: QuizSection,
};
