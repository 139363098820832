import React, { useContext } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable, EditableImg } from '@jsv3/context/EditFieldContext';
import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';

export const ExclusiveOffersImage = () => {
  const { editableImageFields } = useContext(EditFieldContext);

  return (
    editableImageFields.gallery_image_1 && (
      <div className="travel-section__right-vip-image">
        <EditableImg image={editableImageFields.gallery_image_1} width={502} height={556}>
          <Image
            className="image"
            data={editableImageFields.gallery_image_1.content}
            type="PageContent"
            config={{
              size: IMAGE_SIZES.SMALL,
              squared: true,
            }}
            background
          />
        </EditableImg>
      </div>
    )
  );
};

const ExclusiveOffersSection = React.forwardRef(({ id, children }, ref) => {
  const { editableFields } = useContext(EditFieldContext);

  return (
    <>
      {editableFields && (
        <section className="travel-section travel-section--lp4" id={id} ref={ref}>
          <div className="main-container">
            <div className="travel-section__wrapper">
              <div className="travel-section__info travel-section__info--lp4">
                <div className="travel-section__info-wrapper travel-section__info-wrapper--lp4">
                  <div className="section-title section-title--with-underline">
                    <Editable fieldValue={editableFields.exclusive_offers_heading} />
                  </div>

                  <div className="section-description">
                    <Editable fieldValue={editableFields.exclusive_offers_testimonial} />
                  </div>

                  <div className="travel-section__list travel-section__list--lp4">
                    <div className="travel-section__item">
                      <div className="common-title">
                        <Editable fieldValue={editableFields.exclusive_offers_col_1_title} />
                      </div>

                      <div className="common-text">
                        <Editable fieldValue={editableFields.exclusive_offers_col_1_content} />
                      </div>
                    </div>

                    <div className="travel-section__item">
                      <div className="common-title">
                        <Editable fieldValue={editableFields.exclusive_offers_col_2_title} />
                      </div>

                      <div className="common-text">
                        <Editable fieldValue={editableFields.exclusive_offers_col_2_content} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {children}
            </div>
          </div>
        </section>
      )}
    </>
  );
});

ExclusiveOffersSection.displayName = 'ExclusiveOffersSection';

export default ErrorBoundaryDecorator()(ExclusiveOffersSection);
