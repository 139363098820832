import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import PlayButton from '@js/landing/components/PlayButton';
import VideoBlock from '@jsv2/components/Video/VideoBlock';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import LPContext from '@jsv3/context/LPContext';

const VideosSectionWide = ({ poster, id, ...rest }) => {
  const { isAudioPlay, toggleAudioPlayback } = useContext(LPContext);

  return (
    <section className="video-section-wide" id={id}>
      <VideoBlock
        playButton={PlayButton}
        isAudioPlay={isAudioPlay}
        toggleAudioPlayback={toggleAudioPlayback}
        isFreezeFrame
        className="lp-wide-video"
        posterSrc={poster}
        videoSrc="/lp-videos/landing-page-video2.mp4"
        {...rest}
      />
    </section>
  );
};

VideosSectionWide.propTypes = {
  poster: PropTypes.string,
};

VideosSectionWide.defaultProps = {
  poster: '/images/lp/promo2/vip-final-poster-2.jpg',
};

VideosSectionWide.displayName = 'VideosSectionWide';

export default ErrorBoundaryDecorator()(VideosSectionWide);
