import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext from '@jsv3/context/EditFieldContext';
import { IMAGE_SIZES } from '@js/constants';
import { getTopPicks } from '@jsv2/utils/HomePageUtils/APIRequests';
import Slider from '@jsv2/components/Slider/Slider';
import Image from '@jsv2/components/Image';
import SkeletonLoadingItem from '@jsv3/components/atoms/SkeletonLoadingItem';
import LeftContentInSection from '../LeftContentInSection';

const settings = {
  slidesPerView: 1,
  navigation: true,
  breakpoints: {
    1024: {
      slidesPerView: 1.3,
    },
  },
};

const ContentWithOffersSection = ({ id, className }) => {
  const { editableFields } = useContext(EditFieldContext);

  const [topPicks, setTopPicks] = useState([]);
  const [isTopPicksLoading, setIsTopPicksLoading] = useState(false);
  const [isLastSlide, setIsLastSlide] = useState(false);

  const handleSlideChange = (swiper) => {
    if (swiper.isEnd) {
      setIsLastSlide(true);
    } else {
      setIsLastSlide(false);
    }
  };

  useEffect(() => {
    setIsTopPicksLoading(true);

    getTopPicks()
      .then(({ data }) => {
        setTopPicks(data.top_picks);
      })
      .finally(() => setIsTopPicksLoading(false));
  }, []);

  const renderLoading = () => {
    if (isTopPicksLoading) {
      return <SkeletonLoadingItem height="350px" width="100%" />;
    }

    return '';
  };

  return (
    <LeftContentInSection
      id={id}
      childrenClasses={className}
      heading={editableFields.redesign_section_1_heading}
      testimonial={editableFields.redesign_section_1_testimonial}
    >
      {topPicks.length > 0 ? (
        <Slider
          sliderProps={{
            onSlideChange: handleSlideChange,
            className: `content-with-offers__slider${isLastSlide ? ' last-slide' : ''}`,
            ...settings,
          }}
        >
          {topPicks.map((item) => (
            <div key={item.id} className="content-with-offers__slider-item">
              <a href={item.link} className="content-with-offers__slider-item-link" />

              <div className="content-with-offers__slider-item-image">
                {item.image && (
                  <Image
                    data={item.image}
                    config={{
                      size: IMAGE_SIZES.SMALL,
                    }}
                    background
                  />
                )}
              </div>

              <div className="content-with-offers__slider-item-content">
                <p className="mb-0">{item.title}</p>

                <strong>{item.location_tagline}</strong>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        renderLoading()
      )}
    </LeftContentInSection>
  );
};

ContentWithOffersSection.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ContentWithOffersSection.defaultProps = {
  className: '',
};

export default ErrorBoundaryDecorator()(ContentWithOffersSection);
