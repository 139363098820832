import React, { useCallback, useContext, useEffect, useRef } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import LPContext from '@jsv3/context/LPContext';
import Slider from '@jsv2/components/Slider/Slider';
import { PrevArrow, NextArrow } from '@jsv2/components/Slider/SliderArrows';
import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';
import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';
import EditFieldContext, { Editable } from '@jsv3/context/EditFieldContext';

const { benefits } = window;

const BenefitsSection = React.forwardRef(({ id }, ref) => {
  const screenTypeContext = useContext(ScreenTypeContext);
  const { editableFields } = useContext(EditFieldContext);
  const { renderBecomeButton } = useContext(LPContext);

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const setMaxTitleHeight = useCallback(() => {
    const list = document.getElementsByClassName('benefits__item-title');

    let maxHeight = 0;

    for (const item of list) {
      item.style.height = 'auto';

      if (item.clientHeight > maxHeight) {
        maxHeight = item.clientHeight;
      }
    }

    for (const item of list) {
      item.style.height = maxHeight + 'px';
    }
  }, []);

  const maxShowSlides = (showSlides) =>
    benefits.length < showSlides ? benefits.length : showSlides;

  const sliderSettings = {
    loop: true,
    slidesPerView: maxShowSlides(2),
    className: 'benefits__list',
    breakpoints: {
      991: {
        slidesPerView: maxShowSlides(4),
      },
    },
  };

  /**
   * Render single benefit item.
   *
   * @param   {Object}  benefit
   *
   * @returns {JSX.Element}
   */
  const renderBenefit = (benefit) => (
    <div key={benefit.id} className="benefits__item">
      {benefit.icon && (
        <Image
          className="benefits__item-icon"
          data={benefit.icon}
          config={{
            size: IMAGE_SIZES.SMALL,
          }}
          background
        />
      )}

      <div className="benefits__item-title common-title">{benefit.name}</div>

      <div className="benefits__item-text common-text common-text--center">
        {benefit.description}
      </div>
    </div>
  );

  useEffect(() => {
    setMaxTitleHeight();
    window.addEventListener('resize', setMaxTitleHeight, { passive: true });

    return () => {
      window.removeEventListener('resize', setMaxTitleHeight);
    };
  }, [setMaxTitleHeight]);

  return (
    <>
      {editableFields && (
        <section className="benefits" ref={ref} id={id}>
          <div className="benefits__container main-container">
            <div className="section-title section-title--with-underline section-title--center">
              <Editable fieldValue={editableFields.benefits_heading} />
            </div>

            <div className="section-description section-description--center">
              <Editable fieldValue={editableFields.benefits_testimonial} />
            </div>

            {isMobile(screenTypeContext) ? (
              <div className="benefits__list">{benefits.map((item) => renderBenefit(item))}</div>
            ) : (
              <div className="benefits__inner-wrapper">
                <PrevArrow ref={navigationPrevRef} isAbsolute smallArrow />

                <NextArrow ref={navigationNextRef} isAbsolute smallArrow />

                <Slider
                  sliderProps={{
                    navigation: {
                      prevEl: navigationPrevRef.current,
                      nextEl: navigationNextRef.current,
                    },
                    onBeforeInit: (swiper) => {
                      swiper.params.navigation.prevEl = navigationPrevRef.current;
                      swiper.params.navigation.nextEl = navigationNextRef.current;
                    },
                    ...sliderSettings,
                  }}
                >
                  {benefits.map((benefit) => renderBenefit(benefit))}
                </Slider>
              </div>
            )}

            {renderBecomeButton(
              'become-btn become-btn--with-border',
              window.templateVariables.body_cta_text.content,
              'become-join-btn-1',
            )}
          </div>
        </section>
      )}
    </>
  );
});

export default ErrorBoundaryDecorator()(BenefitsSection);
