import React, { useContext, useState, useEffect } from 'react';

import { getArticleListAPI } from '@jsv3/utils/api/articleListAPI';
import BlogSlider from '@js/components/carousels/BlogSlider';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv3/context/EditFieldContext';

const BlogSection = ({ id }) => {
  const { editableFields } = useContext(EditFieldContext);

  const [articles, setArticles] = useState([]);

  useEffect(() => {
    getArticleListAPI().then(({ data }) => {
      setArticles(data || []);
    });
  }, []);

  if (articles.length === 0) {
    return null;
  }

  return (
    editableFields &&
    !!articles.length && (
      <section className="trending" id={id}>
        <div className="main-container trending__container">
          <div className="trending__title">
            <div className="section-title section-title--center section-title--with-underline">
              <Editable fieldValue={editableFields.blog_heading} />
            </div>
          </div>

          <BlogSlider className="promo3" articles={articles} />
        </div>
      </section>
    )
  );
};

export default ErrorBoundaryDecorator()(BlogSection);
