import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import EditFieldContext, { Editable } from '@jsv3/context/EditFieldContext';
import LPContext from '@jsv3/context/LPContext';
import ParserWithFaqsOptions from '@jsv2/config/faqsParserOptions';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const { templateContentAreas } = window;

/**
 * Render faqs
 *
 * @param {string} id
 * @param {string} className
 * @param {boolean} showBecomeButton
 * @param {boolean} smallTitle
 * @param {boolean} hideImage
 *
 * @return {JSX.Element}
 * @constructor
 */
const FaqsSection = ({ id, className, showBecomeButton, smallTitle, hideImage }) => {
  const { editableFields } = useContext(EditFieldContext);
  const { renderBecomeButton } = useContext(LPContext);

  return (
    <section
      className={`faqs-section ${className}${hideImage ? ' faqs-section--no-image' : ''}`}
      id={id}
    >
      <div className="main-container">
        {!smallTitle && (
          <div
            className={`section-title section-title--with-underline section-title--center${
              hideImage ? ' section-title--center section-title--mobile-left' : ''
            }`}
          >
            <Editable fieldValue={editableFields.faqs_heading} />
          </div>
        )}

        <div className="faqs-section__wrapper">
          {smallTitle && (
            <div className="section-title section-title--with-underline section-title--small">
              <Editable fieldValue={editableFields.faqs_heading} />
            </div>
          )}

          <div
            className={`section-testimonial${smallTitle ? ' mt-0' : ''}${
              hideImage
                ? ' section-description--center section-description--mobile-left'
                : ' text-left'
            }`}
          >
            <Editable fieldValue={editableFields.faqs_sub_heading} />
          </div>

          <div className="faqs-section__faqs">
            {ParserWithFaqsOptions(templateContentAreas.faqs)}
          </div>
        </div>

        {showBecomeButton &&
          renderBecomeButton(
            'become-btn become-btn--with-border',
            window.templateVariables.body_cta_text.content,
            'become-join-btn-4',
          )}
      </div>
    </section>
  );
};

FaqsSection.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  showBecomeButton: PropTypes.bool,
  smallTitle: PropTypes.bool,
  hideImage: PropTypes.bool,
};

FaqsSection.defaultProps = {
  className: '',
  showBecomeButton: false,
  smallTitle: false,
  hideImage: false,
};

FaqsSection.displayName = 'FaqsSection';

export default ErrorBoundaryDecorator()(FaqsSection);
