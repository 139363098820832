import React, { useContext } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv3/context/EditFieldContext';
import Slider from '@jsv2/components/Slider/Slider';

const IMAGES = [
  {
    id: 1,
    src: '/images/lp/deposit/client-pic-1.png',
  },
  {
    id: 2,
    src: '/images/lp/deposit/client-pic-2.png',
  },
  {
    id: 3,
    src: '/images/lp/deposit/client-pic-3.png',
  },
  {
    id: 4,
    src: '/images/lp/deposit/client-pic-4.png',
  },
  {
    id: 5,
    src: '/images/lp/deposit/client-pic-5.png',
  },
];

const SLIDER_CONFIG = {
  slidesPerView: 3,
  centeredSlides: true,
  spaceBetween: 1,
  grabCursor: true,
  loop: true,
  className: 'how-started-with-gallery__slider mt-40',
  breakpoints: {
    767: {
      slidesPerView: 5,
    },
  },
};

const HowStartedWithGallery = React.forwardRef(({ id }, ref) => {
  const { editableFields } = useContext(EditFieldContext);

  return (
    editableFields && (
      <section id={id} ref={ref} className="how-started-with-gallery pt-50 pb-50">
        <div className="small-container">
          <div className="section-title section-title--with-underline section-title--center">
            <Editable fieldValue={editableFields.how_started_heading} />
          </div>

          <div className="section-description section-description--center">
            <Editable fieldValue={editableFields.how_started_tagline} />
          </div>
        </div>

        <Slider sliderProps={SLIDER_CONFIG}>
          {IMAGES.map((image) => (
            <div
              key={image.id}
              style={{ backgroundImage: `url(${image.src})` }}
              className="how-started-with-gallery__img"
            />
          ))}
        </Slider>
      </section>
    )
  );
});

HowStartedWithGallery.displayName = 'HowStartedWithGallery';

export default ErrorBoundaryDecorator()(HowStartedWithGallery);
