import React, { useContext } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv3/context/EditFieldContext';

const Quote = React.forwardRef(({ id, quoteVariable = 'quote_content', className = '' }, ref) => {
  const { editableFields } = useContext(EditFieldContext);

  return (
    editableFields && (
      <section id={id} ref={ref} className={`quote-section ${className}`}>
        <div className="small-container">
          <div className="quote-section__quote">
            <Editable fieldValue={editableFields[quoteVariable]} />
          </div>
        </div>
      </section>
    )
  );
});

Quote.displayName = 'Quote';

export default ErrorBoundaryDecorator()(Quote);
