import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv3/context/EditFieldContext';

const ChatSection = ({ className, id }) => {
  const { editableFields } = useContext(EditFieldContext);

  return (
    <>
      {editableFields && (
        <section className={classNames('stylists', className)} id={id}>
          <div className="main-container">
            <div className="stylists__wrapper">
              <div className="stylists__image-wrapper">
                <div className="stylists__icon stylists__icon--whatsapp" />

                <div className="stylists__icon stylists__icon--phone" />

                <div className="stylists__icon stylists__icon--email" />

                <div className="stylists__icon stylists__icon--msg stylists__icon--msg-border" />

                <div className="stylists__image">
                  <div className="stylists__msg stylists__msg--left">
                    <div className="stylists__msg-avatar stylists__msg-avatar--left" />
                    <div className="stylists__msg-text stylists__msg-text--gray">
                      <Editable fieldValue={editableFields.message_1} />
                    </div>
                  </div>

                  <div className="stylists__msg stylists__msg--right">
                    <div className="stylists__msg-avatar stylists__msg-avatar--right" />
                    <div className="stylists__msg-text stylists__msg-text--pink">
                      <Editable fieldValue={editableFields.message_2} />
                    </div>
                  </div>

                  <div className="stylists__msg stylists__msg--left">
                    <div className="stylists__msg-avatar stylists__msg-avatar--left" />
                    <div className="stylists__msg-text stylists__msg-text--gray">
                      <Editable fieldValue={editableFields.message_3} />
                    </div>
                  </div>

                  <div className="stylists__msg stylists__msg--right">
                    <div className="stylists__msg-avatar stylists__msg-avatar--right" />
                    <div className="stylists__msg-text stylists__msg-text--pink stylists__msg-text--dots" />
                  </div>
                </div>
              </div>

              <div className="stylists__info">
                <div className="section-title section-title--with-underline">
                  <Editable fieldValue={editableFields.stylist_heading} />
                </div>

                <div className="section-description">
                  <Editable fieldValue={editableFields.stylist_testimonial} />
                </div>

                <div className="travel-section__list travel-section__list--lp4">
                  <div className="travel-section__item">
                    <div className="common-title">
                      <Editable fieldValue={editableFields.stylist_col_1_title} />
                    </div>

                    <div className="common-text">
                      <Editable fieldValue={editableFields.stylist_col_1_content} />
                    </div>
                  </div>

                  <div className="travel-section__item">
                    <div className="common-title">
                      <Editable fieldValue={editableFields.stylist_col_2_title} />
                    </div>

                    <div className="common-text">
                      <Editable fieldValue={editableFields.stylist_col_2_content} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

ChatSection.propTypes = {
  className: PropTypes.string,
};

ChatSection.defaultProps = {
  className: '',
};

export default ErrorBoundaryDecorator()(ChatSection);
